<template>
  <div id="realNameInfo">
    <div class="bg-color-black">
      <div>
        <div class="d-flex pt-2 pl-2">
          <span style="color: #5cd9e8">
            <icon name="chart-bar"></icon>
          </span>
          <div class="d-flex">
            <span class="fs-xl text mx-2">实名制工种分布图</span>
            <dv-decoration-3 style="
                width: 1.25rem;
                height: 0.25rem;
                position: relative;
                top: -0.0375rem;
              " />
          </div>
        </div>
        <div class="d-flex jc-center">
          <div>
            <Echart :options="options"
                    id="centreLeft1Chart"
                    height="4.05rem"
                    width="4.75rem"></Echart>
          </div>
        </div>

        <div class="bottom-data">
          <div class="item-box"
               v-for="(item, index) in numberData"
               :key="index">
            <div class="d-flex">
              <!-- <span class="coin">人</span> -->
              <dv-digital-flop :config="item.number"
                               style="width: 1.05rem; height: 0.55rem; margin: 0 AUTO" />
            </div>
            <p class="text"
               style="text-align: center">
              {{ item.text }}
              <span class="colorYellow">(人)</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
  props: {
    pro: {
      type: Object,
    },
  },
  data () {
    return {
      options: {},
      cdata: {
        xData: ["1"],
        seriesData: [{ value: 10, name: "1" }],
      },
      numberData: [
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}",
          },
          text: "实时人数",
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}",
          },
          text: "劳务人员",
        },
        {
          number: {
            number: [0],
            toFixed: 0,
            content: "{nt}",
          },
          text: "管理人员",
        },
      ],
      mCount: 0,
    };
  },
  components: {
    Echart,
  },
  mounted () {
    this.changeNumber();
    this.changeTiming();
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          color: [
            "#37a2da",
            "#32c5e9",
            "#9fe6b8",
            "#ffdb5c",
            "#ff9f7f",
            "#fb7293",
            "#e7bcf3",
            "#8378ea",
          ],
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} ({d}%)",
          },
          toolbox: {
            show: true,
          },
          calculable: true,
          legend: {
            orient: "horizontal",
            icon: "circle",
            bottom: 0,
            x: "center",
            data: newData.xData,
            textStyle: {
              color: "#fff",
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              /*  radius: [10, 60],
              roseType: "area", */
              radius: "50%",
              center: ["50%", "40%"],
              data: newData.seriesData,
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
    pro: {
      deep: true,
      handler () {
        this.changeNumber();
      }
    }
  },
  methods: {
    changeTiming () {
      setInterval(() => {
        this.changeNumber();
      }, 30000);
    },
    changeNumber () {
      this.$http
        .post("/PersonelManage/Attendance/GetCurrentCount", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.numberData = [];
            this.numberData.push({
              number: {
                number: [resJson.Data.OnSiteTotal],
                toFixed: 0,
                content: "{nt}",
              },
              text: "实时人数",
            });
            this.numberData.push({
              number: {
                number: [resJson.Data.WCount],
                toFixed: 0,
                content: "{nt}",
              },
              text: "劳务人员",
            });
            this.numberData.push({
              number: {
                number: [resJson.Data.MCount],
                toFixed: 0,
                content: "{nt}",
              },
              text: "管理人员",
            });
            this.mCount = resJson.Data.MCount;
            console.log(resJson);
          }
        });
      this.$http
        .post("/PersonelManage/Attendance/GetTypeCount", {
          ProId: this.pro.Id,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.cdata.xData = [];
            this.cdata.seriesData = [];
            console.log(resJson);
            for (let index = 0; index < resJson.Data.length; index++) {
              const element = resJson.Data[index];
              if (element.Key === "") {
                console.log(this.mCount);
                if (this.mCount == element.Count) {
                  this.cdata.xData.push("项目管理人员");
                  this.cdata.seriesData.push({
                    value: element.Count,
                    name: "项目管理人员:" + element.Count,
                  });
                } else {
                  this.cdata.xData.push("未设置工种");
                  this.cdata.seriesData.push({
                    value: element.Count,
                    name: "未设置工种:" + element.Count,
                  });
                }
              } else {
                this.cdata.xData.push(element.Key);
                this.cdata.seriesData.push({
                  value: element.Count,
                  name: element.Key + ":" + element.Count,
                });
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
#realNameInfo {
  padding: 0.2rem;
  height: 6.125rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 5.8125rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }

  .bottom-data {
    .item-box {
      float: right;
      position: relative;
      width: 32%;
      margin-top: 0.2125rem;
      color: #d3d6dd !important;
      // 金币
      .coin {
        position: absolute;
        left: 0.1rem;
        top: 0.2125rem;
        font-size: 0.25rem;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      .ant-list {
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-list-item {
              border-bottom: 0px;
              .ant-list-item-meta {
                .ant-list-item-meta-content {
                  .ant-list-item-meta-title {
                    color: #c3cbde !important;
                  }
                  .ant-list-item-meta-description {
                    color: aliceblue !important;
                    font-size: 12px;
                    padding-left: 25px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>